@import "sass-lib";
@media all and(max-width: 1240px) {

	.bodysec .title {
		font-size: 36px;
		.home-slide{
			.wrapper{
				.slider{
					.slick-dots{
						li{
							&:before{ vertical-align:top; }
							button{ @include transform(translateX(-50%,5%)); }
						}
					}
				}
			}
		}
	}
	header{
		height:54.3px;
		&:before{ width:50%; }
		.wrapper{
			padding: 0 40px;
			.logo{
				margin-top: 5px;
				img{ width: 129px; }
			}
			.rh_head{
				padding-top: 13px; margin-right: 40px; width: auto;
				nav{
					.mdesktop{ display: none; }
					ul li{
						a:before{ display:none; }
					}
					.mmobile{
						position: fixed; top: 0; right: 0;background: #FFFF00; width: 50%; z-index: 5; display: none; height: 100%; box-sizing: border-box;  overflow: auto; margin-top: 0; padding: 0 35px; padding-top: 54.3px;
						li{
							display: block; margin: 15px 0; position: relative; float: none; text-align: left;
							a{ 
								font-size: 20px; font-weight: 700; text-align: left;
								&:hover, &.active{
									color:#ba0716;
									&:before{ display:none; }
								}
							}
							.subdrop{ 
								position: relative; top: 0; padding-top: 0; left: 0px; width: auto; transform: unset; width: auto; max-width: unset;
								ul{ 
									background: transparent; float: none;
									li{
										border: none; padding: 10px 0;
										&:hover{ background: transparent; }
										a{ 
											color: #333333; font-size: 18px; position: relative; padding-left: 0;  -webkit-transition: all .5s ease-in-out;-moz-transition: all .5s ease-in-out;transition: all .5s ease-in-out; display: block; line-height: 24px;
										}
										.snav{
											ul li{
												a{ color: #333; }
											}
										}
									} 
								}
								.snav{
									ul li{
										&:hover{
											.smenu{
												li{ background:#fff; }
											}
										}
										.smenu{
											&:before{ display:none; }
											li{
												padding: 10px 20px; padding-right: 0;
												a{ color: #333; }
											}
										}
									}
								}
								&:before{ display:none; }
							}
						}
						.lang-mobile{
							width: 100%; border: none; background: transparent; position: relative; font-size: 15px; font-weight: bold; line-height: 1.33;text-align: left; cursor: pointer;color: #333333; font-size: 18px; position: relative; padding-left: 0;  -webkit-transition: all .5s ease-in-out;-moz-transition: all .5s ease-in-out;transition: all .5s ease-in-out; display: block; line-height: 24px;
							&:after{ position:absolute; content: ""; background: url(../images/material/arrow-down-red.png) no-repeat center right; width: 15px; height: 20px; right: -10px; }
						}
					}
					.burg_nav{
						display: block;position: relative;width: 34px;text-align: right;cursor: pointer; float: right; cursor: pointer; right: -40px; z-index: 9;
						span{
							display: block;width: 100%;height: 2px;background: #3b3b3b;float: right;margin-bottom: 10px;
							&:nth-child(2){ width:75%; }
						}
					}
					.thumb-header{
						.col{ 
							display: block; position: absolute; width: 120%; right: 0px; z-index: 9;
							&.language{ display: none; }
						}
						.sign{
							a{ 
								padding: 0; display: block;
								img{ display: inline-block; vertical-align: middle; }
							}
						}
					}
					> ul{
						margin-right: 0;
					}
				}
			}
		}
	}
	.bodysec{
		padding-top: 54.3px;
		.home-slide{
			.wrapper{ 
				width: auto; margin: 0 40px; 
				.slider{
					.slick-dots{
						li{
							padding: 13px 0; width: 40px;
						}
					}
					.slide{
						.caption{ left: 59px; }
					}
				}
				.socmed{ display: none; }
			}
			.scroll-down{ display: none; }
			.slick-arrow{ 
                position: absolute; left: 59px; bottom: 22.6px; border:none; background: none; font-size: 0; width: 35px; height: 35px; cursor: pointer; z-index: 5;
                &.slick-next{ 
                    background: url(../images/material/arrowright-white.png) no-repeat; background-size: 100%;
                    &:before{ position:absolute; content: ""; background: rgba(255,255,255,0.2); height: 1px; width: 100%; top: -5px; left: 0; }
                }
                &.slick-prev{ background: url(../images/material/arrowleft-white.png) no-repeat; background-size: 100%; bottom: 70px; }
                &.slick-disabled{ opacity: 0.69; cursor: default; }
            }
		}
		.home-about{
			padding-top: 50px;
			&:before{ left:40px; }
			.left{ margin-left: 81.3px; width: 40%; }
			.right{ margin-right: 77.5px;  width: 40%; margin-top: 0; }
		}
		.home-service{
			margin: 81px 77.5px 0 81.5px;
			.highlight{
				.left,.right{ float: none; }
				.right{
					p{ margin-left: 30px; }
				}
			}
			.box-layanan {
				.slick-arrow {
					width: 28px; height: 28px; background-size: 100% !important; bottom: 60px; right: -35px;
					&.slick-prev {bottom: 100px;}
				}
			}
		}
		.home-promote{
			.wrapper{
				&:before{
					height: 343px;
				}
			}
			.slider{
				margin: -40px 40px;
				.slide {
					.content {right: 65px;}
				}
			}
		}
		.home-news{
			.wrapper{
				padding-top: 124px; margin: 0 80px;
			}
		}
		.home-client{
			margin: 57.6px 0;
			.wrapper{
				margin: 0 57.7px;
				.right, .left{ float: none; }
				.right{
					p,.btn_round{ margin-left: 30px; }
				}
				.left{
					.slider{
						margin-top: 30px; padding-left: 100px; box-sizing: border-box;
						&:before{ left:47px; top:49.8%; }
						&:after{ top:50%;  right: -23px; }
						.slick-arrow{ 
							left: 50px; 
							&.slick-next{ bottom: 38%; }
						}
						.slide{
							.col{
								.list{
									width: 48%;
								}
							}
						}
					}
				}
			}
		}
		.home-map{
			.map{ width: auto; margin: 0 70px; height: 534px; }
			.wrapper:before{ bottom: -1px; }
		}
	}
	.bg-yellow-homepage,.bg-yellow-small{ width: 50%; }
	.banner{
		.capt_abs{ left: 59px; }
	}
	.middle{
		padding-top: 54.3px;
		.wrapper{
			width: auto; margin: 0 40px;
		}
	}
	.banner-bottom-abs{
		height: 550px; margin: 0 -50px;
		.text{ right: 70px; }
		figure{
			img{ height: 500px; overflow: hidden; object-fit: cover; object-position: top left; }
			.bg{ height: 500px; width: 450px; object-fit: cover;  }
		}
	}
	.inner-content{
		padding: 70px 50px 0;
		.bg_top_left{
			width: 253px; height: 212px;
		}
		.bg_wht_top{ top: -40px; width: 95%;}
	}
	.bg-yellow-bot{
		&.aboutus{ width: 253px; height: 284px;  }
	}
	.wrap-bot{ margin-top: 53px; }
	.sertifikat{
		margin: 50px 0;	
	}
	.headline-content{
		.right,.left{ float: none; }
	}
	.milestone{
		margin: 0 -50px; margin-bottom: 95px;
		.col{
			.box{
				.left{ 
					padding-left: 0; 
					.images{
						width: 383px;
					}
					&:before{ width: 85px; }
				}
				.right{
					.content{
						ul li{
							padding-left: 90px;
							&:before{ width: 85px; }
						}
					}
				}
			}
			&:nth-child(even){
				.left{
					.images{ float: right; }
				}
			}
		}
	}
	.packet-box{
		.col{
			.list{
				width: 48%;
				&:nth-child(even){ margin-right: 0; }
				.title{ margin-bottom: 15px; }
				.content{
					ul{
						margin-left: 0;
					}
				}
			}
		}
	}
	.info-periode{
		display: none;
	}
	.quote{
		margin: 0; padding: 110px 30px 80px 30px;
	}
	.packet-box{
		.col{
			.list{
				&:nth-child(3n+3){ margin-right: 21px; }
				&:nth-child(2n+2){ margin-right: 0px; }
			}
		}
	}
	.detail-page{
		.rh-content{ display: none; }
		.lf-content{ 
			width: 90%; padding: 0px; 
		}
	}
	.bg_btm_rfull{ top: -60px; }
	.middle{
		&.pattern-right{
			&:before{ display:none; }
		}
	}
	.map-banner{
		.find-loc{ 
			width: 372px; padding: 175px 40px;
			h3{ font-size: 40px; } 
		}
		.map-wrap{ padding-left: 372px; }
	}
	.box-event{
		.list{ width: 50%; }
	}
	.box-blog{
		.col{ 
			width: 48%;
			&:nth-child(3n+3){ margin-right: 20px; } 
			&:nth-child(2n+2){ margin-right: 0; } 
			.content{
				margin-bottom: 0;
				h4{ font-size: 17px; }
			}
		}
	}
	.thumbfacilities{ 
		width: auto; 
		.slick-arrow{
			&.slick-prev{ left: -25px; }
			&.slick-next{ right: -25px; }
		}
	}
	.wrap-facilities{
		&:before{ left:10px; right: 10px; }
	}
	.top-title{
		/*margin-bottom: 0;*/
		.left, .right{ float: none; display: block; }
		.right{
			 width: auto;
			p{ margin-left: 0; }
		}
		.title-pg.left{ 
			width: 47%;
			/*b{ margin-left: 28.5px; } */
		}
		.filter-top{ float: none; }
	}
	.slideFacilities{
		.slide{
			.bx-slide{
				.dec-facilities{ 
					padding: 33px; 
					display: block; 
					p{ margin-left: 0; }
					.title-pg{ margin-left: 0; }
					.txt{ top: 0; @include transform(translateY(0)); }
				}
			}
		}
	}
	.wrap-lab{
		.wrp-images{ transform: scale(0.7); margin-top: -25px; margin-bottom: -50px;}
	}
	.ls_faci{ 
		padding-top: 0;
		li{ width: 100%; }
	}
	.bg_bf{ 
		height: 220px; object-fit: cover; 
		&:after{ top:-220px; }
	}
	.ls_block{
		.item{ margin-bottom: 48.5px; }
	}
	.mini-banner-content{
		width: 100%;
	}
	.wrap_signin{ 
		padding-top: 54.3px;overflow-x: hidden; display: block;
 		.img{ 
			width: 49%; padding-left: 40px;
			img{ height: 100%; overflow: hidden; object-fit: cover; }
		}
		.content-signin{
			width: 51%; padding: 50px 0; box-sizing: border-box;
			.v-text{
				width: 85%; @include transform(translatey(0%));
				h1{ font-size: 50px; }
			}
		}
	}
	.list_laporan{
		.ls{ 
			width: 47%; 
			&:nth-child(3n){ margin-right: 3.33%; }
			&:nth-child(2n){ margin-right: 0; }
		}
	}
	.slider-client-misi{ 
		width: auto; 
		.list{
			.col{ 
				width: 100%; 
				&:hover {
	                figure {
	                    img { @include transform(scale(1));}
	                }
	            }
			}
		}
		.slick-arrow{
			right: -50px;
		}
	}
	.banner-full{
		.mcu-wrap{ margin: 0; margin-top: -90px; }
	}
	.static-pages{
		.wrapper{
			padding: 50px;
		}
		.images{
			.bg-img{ height: 800px; }
		}
	}
	.wrap_pop{
		.pop_inner{
			.content_pop{
				margin: 8% auto;
			}
		}
	}
	.inner-content{
		.content{
			margin-bottom: 30px;
		}
	}
	footer{
		.darkbox{
			.middle-footer{
				.col{ 
					width: 100%; margin-left: 0;
					.right{ width: 40%; }
					&:nth-child(2){ margin-left: 0; margin-top: 15px; } 
					&:first-child:before{
						height: 2px; width: 100%; bottom: 0;top: unset; opacity: 0.3;
					}
				}
			}
		}
		&:before{ width: 50%; }
	}
}
@media all and(max-width: 1020px) {
	header{
		.wrapper{ padding: 0 30px; }
	}
	.bodysec{
		.home-slide{
			.wrapper{
				margin: 0 30px;
				.slider{
					.slide{
						img{ width: 100%; }
						.caption{
							width: 500px;
							h5{ font-size: 10px; }
						}
					}
				}
				.nav_slide{ width: 384px; }
			}
		}
		.home-about{
			&:before{ width:296px; height: 246px; left: 30px; }
			.wrapper{ margin: 0 30px; width: auto; padding-left: 48.2px; box-sizing: border-box; }
			.left,.right{ float: none; display: block; }
			.left{ margin: 0; width: 70%; }
			.right{ 
				margin-top: 40px; width: auto; 
				.content{
					p{ margin-bottom: 38px; }
				}
			}
		}
		.home-service{
			.highlight{
				.left, .right{ display: block; width: auto; }
			}
			.box-layanan{
				.list{
					.content{
						left:20px; right: 20px;
						.left{
							width: 153px;
							h4{ font-size: 17px; height: 75px; }
							.icon{ width: 34px; }
						}
						.right{
							.btn-circle:before{ width:34px;top: 0px; }
						}
					}
				}
			}
		}
		.home-promote{
			.slider{
				margin: -40px 30px;
				.slide{
					.images{
						img{overflow: hidden; width: 100%;}
					}
					.content{
						right: 28.5px;
						.title{ font-size: 30px; }
					}
				}
			}
		}
		.home-map{
			.wrapper{
				&:before{ left:30px; right: 30px; }
			}
			.map{ margin: 0 50px; }
		}
		.home-client{
			.wrapper{
				.left{
					.slider{
						&:after{ right:-23px; }
						.slide{
							.col{
								.list{ width: 47%; }
							}
						}
						.slick-arrow{
							&.slick-next{ bottom: 33%; }
						}
					}
				}
			}
		}
	}
	.qoutes{
		.bg{
			background: url(../images/material/bg-slider-client.png) center; height: 320px;
		}
		.slider-client-qoutes{ width: 500px; }
	}
	.middle{
		.wrapper{
			margin: 0 30px;
		}
	}
	.banner{
		.capt_abs{
			width: 500px;
			h6{ font-size: 10px; }
		}
	}
	.ls_block{
		.item{
			.img{ float: none; display: block;width: 80%; }
			.dec_item{ float: none; display: block; width: auto; margin-top: 30px; }
		}
	}
	.inner-content{
		padding: 50px 30px 0;
	}
	.banner-bottom-abs{ margin: 0 -30px; }
	.wrap-lab{
		height: 580px;
		.wrp-images{
			transform: scale(1); padding-top: 0; width: 480px; margin: 100px auto 0px;
			figure {height: 580px; padding: 207px 22px 0;}
		}
		.wrp-absolute{
			top: 90px;
			.abs{
				&.img1{ top: unset; bottom: -30px; }
				&.img2{ bottom: 0px }
				&.img3{ top: unset; bottom: 0px; }
			}
		}
	}
	.bg_bf{
		&:after{ height:143px; top: -180px; }
	}
	.ls_faci{
		justify-content: unset;
		li{ width: 28.3%; padding-right: 0;}
	}
	.slideFacilities{
		.slide{
			.bx-slide{
				figure, .dec-facilities{ width: auto; }
			}
		}
	}
	.wrap-facilities{
		&:before{ left:-30px; right:-30px; }
	}
	.top-title{ margin-bottom: 30px; }
	.inner-content{ 
		padding: 50px 40px 0;
		p{ margin-left: 0; }	
		.bg_top_left{
			&.long{ width: 233px; height: 335px; }
		}	
	}
	.packet-box{
		.col{
			.list{ 
				width: 100%; margin: 0 0px 30px; 
				.btn_round{ padding: 0 30px; font-size: 12px; left: 50%; }
			}
		}
	}
	.detail-page{
		.wrp-text{
			.column{ width: auto; margin-right: 0; }
		}
		&.det-event{
			.lf-content{ padding-right: 40px; }
		}
	}
	.box-form{
		&.brder{
			.row{
				.col{
					&.col5{ 
						width: 100%; margin-right: 0; margin-bottom: 35px; 
						&:last-child{ margin-bottom:0; }
					}
				}
				&:last-child{
					.col5{ width: 50%; }
				}
			}
		}
	}
	.box-event{
		.list{
			width: 100%; padding-right: 0; margin-bottom: 80px;
			.images{
				img{ width: 100%; }
			}
			.content{ margin-bottom: 50px; }
		}
	}
	.pagination{
		margin: 60px 0;
	}
	.box-blog{
		.col{
			width: auto; margin-right: 0;
			.images{
				img{ width: 100%; }
			}
			.content{
				padding: 40px 10px;
				h3{ font-size: 35px; }
			}
		}
	}
	.map-banner{
		.map-wrap{ height: 1098px; padding-left: 0; }
		.find-loc{
			width: 100%; height: 452px; background: rgba(255, 255, 0, 0.9); padding: 75px 55px;
		}
	}
	.ls_block{
		.item{
			.img{ width: 100%; }
			h5{ width: unset; }
		}
	}
	.milestone{
		margin: 0px;
		.col{
			.box{
				padding-top: 50px; padding-bottom: 130px; position: relative; margin-bottom: 20px;
				&:before{ height:100px; }
				&:after{ display: none; }
				.left, .right{ float: none; display: block;width: auto; }
				.left{
					.images{ width: 100%; }
					&:before{ display: none; }
				}
				.right{
					.content{
						margin: 0; margin-top: 30px; 
						ul li{ padding-left: 0; }
					}
				}
			}
			h3{
				font-size: 20px;
				&:after{ display:none; }
			}
			&:nth-child(even){
				.right{
					.content{
						ul li{ 
							padding-right: 0; 
							&:before{ display:none; }
						}
					}
				}
			}
			&:nth-last-child(2){
				.box{ 
					padding-bottom: 0; margin-bottom: 100px;
					&:before{ display:none; }
				}
			}
		}
	}
	.wrap_signin{
		.img{ display: none; }
		.content-signin{ 
			width: 100%; height: auto;  min-height: 800px;
			&:after{ display:none; }
			.v-text{ /*@include transform(translatey(-50%));*/ top:unset; }
			&.regist{
				.v-text{ @include transform(unset); top: unset; }
			}
		}
	}
	.copyright{
		.cr_lft{
			&:before{ display:none; }
		}
	}
	.banner-full{
		.mcu-wrap{
			margin-top: -90px;
			.info_user{
				h4{ color: #333; }
			}
			.ic_user{ margin: 0 auto }
		}
		figure{
			img{ overflow: hidden; object-fit: cover; height: 150px; }
		}
	}
	.list_laporan{
		.ls{ width: auto; margin-right: 0; }
	}
	.bg_bf {
		height: 306px;
		&:after {top: -140px;}
	}

	.wrap-lab {
		.wrp-absolute {
			top: 110px;
			.dot {width: 16px; height: 16px;}
			.abs {
				display: block !important; width: 170px !important;
				.img {width: 120px !important; margin: auto;}
				h5 {font-size: 16px; margin-bottom: 10px !important; width: auto !important; padding: 0  40px !important; text-align: center;}
				&.img1 {
					left: -60px; bottom: -50px;
					.dot {right: 0;}
				}
				&.img2 {
					h5 {padding: 0px !important;}
				}
				&.img3 {
					right: -60px; bottom: -20px;
					.dot {left: -10px; bottom: -25px;}
				}
			}
		}
	}
	.ls_faci {
		li {
			width: 100%; margin: 0 2% 20px 0 !important ;
			&:nth-child(3n) {margin-right: 0 !important;}
		}
	}
	.detail-page{
		&.det-event{
			.lf-content{
				iframe{ height: 370px; }
			}
		}
	}
	.wrap_pop{
		.pop_inner{
			.content_pop{
				width: 675px; margin: 10% auto;
				.capt{ 
					width: 640px; 
					p{ font-size: 16px; }
				}
			}
		}
		.slide_Thumb{ 
			width: 640px;
			.thumb{
				img{ width: 170px; }
			} 
		}
		.galery_pop{
			.slick-arrow{
				&.slick-prev{ left: 30px; }
				&.slick-next{ right: 30px; }
			}
		}
	}
	.static-pages{
		.wrapper{
			padding: 50px;
		}
		.images{
			.bg-img{ height: 800px; }
		}
		.content{
			width: 500px;
			h1{ font-size: 45px; line-height: 45px; }
		}
	}
	.slideFacilities{
		.slide{
			.bx-slide{ 
				display: block; 
				.dec-facilities{
					.txt{ top: 0; @include transform(translateY(0)); }
				}
			}
			.count-dot{
				font-size: 100px; right: 100px; top: 0px;
			}
		}
	}
	.headline-content{
		.row{
			.col{ width: 33.3%; }
		}
	}
	.map-banner{
		.find-loc{ position: relative; padding: 30px; height: auto; }
		.map-wrap{ height: 300px; }
	}
	footer{
		.wrapper{ margin: 0 30px; }
		.darkbox{
			padding: 33px 30px 64px 30px;
		}
	}
}